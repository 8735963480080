/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'box2-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M3.75 0a1 1 0 00-.8.4L.1 4.2a.5.5 0 00-.1.3V15a1 1 0 001 1h14a1 1 0 001-1V4.5a.5.5 0 00-.1-.3L13.05.4a1 1 0 00-.8-.4zM15 4.667V5H1v-.333L1.5 4h6V1h1v3h6z"/>',
    },
});
